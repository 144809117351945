<template>
  <v-container no-gutters>
    <v-row>
      <v-col>
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="from1"
              label="От"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="from"
            @input="menu1 = false"
            :max="to"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="to1"
              label="До"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="to"
            @input="menu2 = false"
            :max="now"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-btn color="primary" elevation="2" large @click="getTimeCost"
          >Показать</v-btn
        >
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="col-xl-4 col-lg-6 col-xs-12">
        <apexchart
          width="100%"
          ref="realtimeChart"
          type="pie"
          :options="options"
          :series="series"
        ></apexchart>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="flex-sm-column">
        <v-data-table
          current="ru"
          dense
          :headers="headers"
          :items="costs"
          :items-per-page="15"
          v-model="selected"
          item-key="id"
          class="elevation-1"
          show-select
        >
          <template v-slot:[`item.file`]="{ item }">
            <v-btn v-if="item.file" @click="openPhoto(item.file)"> Фото </v-btn>
          </template>
          <template v-slot:footer>
            <v-row no-gutters class="prefooter">
              <v-spacer></v-spacer>
              <v-col class="pa-2" style="display: inline-block">
                <div style="width: 120px; margin-left: auto">
                  <b>Всего: {{ total_items }}</b>
                </div>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="photo_dialog" width="500">
      <v-btn icon dark @click="photo_dialog = false" class="close_icon">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-card dark justify="center" style="position: relative; z-index: 50">
        <v-card-action>
          <v-img
            max-width="90vw"
            max-height="90vh"
            height="90vh"
            class="grey darken-4"
            :src="`https://old.rem.biz/${photo}`"
          >
          </v-img>
        </v-card-action>
      </v-card>
    </v-dialog>
    <v-row class="flex-xs-column">
      <v-col v-if="selected.length > 0 && key_aproove">
        <v-btn
          depressed
          color="error"
          block
          class="col-2 col-sm-12"
          @click="deleteItems"
        >
          Удалить
        </v-btn>
      </v-col>
      <v-col v-if="selected.length == 1 && key_aproove">
        <v-dialog v-model="dialog_edit" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success"
              dark
              v-bind="attrs"
              v-on="on"
              block
              class="col-2 col-xs-12"
            >
              Редактировать
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">Редактировать</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      :items="select_items"
                      label="Тип поломки"
                      required
                      v-model="edit_item.title"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Стоимость"
                      v-model="edit_item.price"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="table_type == 'collection'">
                    <v-text-field
                      label="Квитанция"
                      v-model="edit_item.order"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      name="input-7-1"
                      label="Комментарий"
                      v-model="edit_item.comment"
                      hint="Введите текст комменария"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog_edit = false">
                Закрыть
              </v-btn>
              <v-btn color="blue darken-1" text @click="editItem">
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-spacer> </v-spacer>
      <v-spacer> </v-spacer>
      <v-col class="col-lg-2 col-sm-12">
        <v-dialog v-model="dialog" persistent max-width="400px">
          <template v-slot:activator="{ on, attrs }">
            <v-spacer></v-spacer>
            <v-btn color="primary" dark v-bind="attrs" v-on="on" col="2" block>
              Добавить
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">Добавить</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      :items="select_items"
                      label="Расходы"
                      required
                      v-model="new_item.title"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Стоимость"
                      v-model="new_item.price"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="table_type == 'collection'">
                    <v-text-field
                      label="Квитанция"
                      v-model="new_item.order"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      name="input-7-1"
                      label="Комментарий"
                      v-model="new_item.comment"
                      hint="Введите текст комменария"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-menu
                      v-model="menu_form"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="new_item.date"
                          label="От"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="new_item.date"
                        @input="menu_form = false"
                        :max="to"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-file-input label="Прикрепить файл" v-model="file">
                  </v-file-input>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Закрыть
              </v-btn>
              <v-btn color="blue darken-1" text @click="addItem">
                Добавить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import axios from "axios";

export default defineComponent({
  name: "Costs",
  props: ["key_aproove"],
  data: () => ({
    dialog_delete: false,
    key: "",
    file: "",
    error: [],
    photo: "",
    photo_dialog: false,
    totals: {
      total: 0,
    },
    total_items: 0,
    selected: [],
    dialog_edit: false,
    dialog: false,
    new_item: {
      title: "",
      order: "",
      price: 0,
      comment: "",
      table_type: "",
      date: null,
    },
    select_items: [],
    headers1: [
      { text: "Тип расхода", sortable: false, value: "title" },
      { text: "Квитанция", sortable: false, value: "order" },
      { text: "Дата", sortable: false, value: "date", width: "150px" },
      { text: "Комментарий", sortable: false, value: "comment" },
      { text: "Стоимость", sortable: false, value: "price", width: "150px" },
      { text: "Файл", sortable: false, value: "file" },
    ],
    headers: [],
    costs: [],
    from: "",
    to: "",
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu1: false,
    menu2: false,
    menu_form: false,
    now: new Date().toISOString().substr(0, 10),
    series: [],
    options: {
      labels: [],
      legend: {
        position: "right",
      },
      responsive: [
        {
          breakpoint: 900,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  }),
  created() {
    this.table_type = this.$route.meta.table_type;
    this.headers = this.headers1.slice();
    if (this.table_type == "common_costs") {
      this.headers.splice(1, 1);
    }
  },
  mounted() {
    if (!this.key_aproove) {
      this.$router.push("/");
    }
    this.new_item.table_type = this.table_type;
    let date = new Date();
    this.from =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2);
    this.to =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2);
    this.now =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2);

    this.getTimeCost();
    this.getCostTypes();
  },
  methods: {
    openPhoto: function(path) {
      this.photo = path;
      this.photo_dialog = true;
    },
    proove: function() {
      axios
        .post("https://old.rem.biz/proove.php", {
          key: this.key,
        })
        .then((response) => {
          if (response.data == "ok") {
            this.deleteItems();
            this.key = "";
            this.dialog_delete = false;
          } else {
            this.error = [];
            this.error.push("Неверно");
          }
        });
    },
    getCostTypes: function() {
      if (this.table_type != "collection") {
        axios
          .get(`https://old.rem.biz/costsTypes.php?td=costs_types`)
          .then((response) => {
            this.select_items = [];
            response.data.forEach((el) => {
              this.select_items.push(el.title);
            });
          });
      } else {
        this.select_items = [
          "Запчасти",
          "Хознужды",
          "Канцелярия",
          "Возвраты",
          "Расходные материалы",
        ];
      }
    },
    calcStat: function() {
      this.totals = {};
      this.total_items = 0;
      this.costs.forEach((el) => {
        if (!this.totals[el.title]) {
          this.totals[el.title] = 0;
        }

        el.price = parseInt(el.price);
        this.totals[el.title] = this.totals[el.title] + parseInt(el.price);
        this.total_items = this.total_items + parseInt(el.price);
      });
      this.costs.reverse();
      let labels = [];
      this.series = [];
      for (let prop in this.totals) {
        labels.push(`${prop} ${this.totals[prop]}₽`);
        this.series.push(this.totals[prop]);
      }
      this.options = {
        labels: labels,
        responsive: [
          {
            breakpoint: 90000,
            options: {
              legend: {
                position: "right",
              },
            },
          },
          {
            breakpoint: 900,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
    addItem: function() {
      if (!this.file) {
        axios
          .post("https://old.rem.biz/createCollection.php", this.new_item)
          .then((response) => {
            if (response.text != "error") {
              response.data.date = new Date(
                response.data.date
              ).toLocaleDateString();
              this.costs.push(response.data);
              this.new_item = {
                title: "",
                price: 0,
                comment: "",
                order: "",
                table_type: this.table_type,
              };
            }
            this.calcStat();
            this.dialog = false;
          });
      } else {
        let data = new FormData();
        data.append("image", this.file);
        for (let val in this.new_item) {
          data.append(val, this.new_item[val]);
        }
        axios
          .post("https://old.rem.biz/createCollectionWithFile.php", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.text != "error") {
              response.data.date = new Date(
                response.data.date
              ).toLocaleDateString();
              this.costs.push(response.data);
              this.new_item = {
                title: "",
                price: 0,
                order: "",
                comment: "",
                table_type: this.table_type,
              };
              this.file = "";
            }
            this.calcStat();
            this.dialog = false;
          });
      }
    },
    editItem: function() {
      this.edit_item.table_type = this.table_type;
      axios
        .post("https://old.rem.biz/updateCollection.php", this.edit_item)
        .then((response) => {
          if (response.text != "error") {
            this.dialog_edit = false;
            this.selected = [];
          }
          this.calcStat();
        });
    },
    deleteItems: function() {
      let ids = this.selected.map((el) => {
        return el.id;
      });
      axios
        .get("https://old.rem.biz/deleteCostsType.php", {
          params: {
            to_del: ids,
            table: this.table_type,
          },
        })
        .then((response) => {
          if (response.data == "ok") {
            ids.forEach((id) => {
              let ind = this.costs.findIndex((el) => {
                return el.id == id;
              });

              this.costs.splice(ind, 1);
            });
            this.selected = [];
            this.calcStat();
          }
        });
    },
    getTimeCost: function() {
      axios
        .get(
          `https://old.rem.biz/getTimeCollection.php?from=${this.from}&to=${this.to}&table=${this.table_type}`
        )
        .then((response) => {
          this.costs = response.data;
          this.costs.forEach((el) => {
            el.date = new Date(el.date).toLocaleDateString();
          });
          this.calcStat();
        });
    },
  },
  computed: {
    edit_item: function() {
      return this.selected[0];
    },
    to1: function() {
      return new Date(this.to).toLocaleDateString();
    },
    from1: function() {
      return new Date(this.from).toLocaleDateString();
    },
    total: function() {
      return this.total_aparts + this.total_job;
    },
  },
  watch: {
    $route: function() {
      this.table_type = this.$route.meta.table_type;
      this.new_item.table_type = this.table_type;
      this.headers = this.headers1.slice();
      if (this.table_type == "common_costs") {
        this.headers.splice(1, 1);
      }
      this.getTimeCost();
      this.getCostTypes();
    },
  },
});
</script>
<style>
.prefooter {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
.close_icon {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 100;
}
</style>
