<template>
  <v-container no-gutters>
    <div>
      <v-row>
        <v-col cols="12">
          <h2>{{ user.name }}</h2>
        </v-col>
        <v-col>
          <p>{{ user.position }}</p>
        </v-col>
      </v-row>
    </div>
    <v-col cols="12"><h2 style="text-aligin: center">Система оплаты</h2></v-col>
    <v-row class="elevation-4 ma-1 mb-3">
      <v-radio-group v-model="user.salary_setting.use_common_costs">
        <v-radio label="Рассчитывать с личного дохода работника" :value="0">
        </v-radio>
        <v-radio
          label="Рассчитывать с общих доходов всех работников"
          :value="1"
        >
        </v-radio>
        <v-radio label="Рассчитывать с чистой прибыли" :value="2"> </v-radio>
      </v-radio-group>
    </v-row>
    <v-row cols="12" class="elevation-4 ma-1 mb-3">
      <v-col cols="12">
        <v-radio-group v-model="user.salary_setting.useProcent">
          <v-row align="center">
            <v-col cols="12" md="2">
              <v-radio label="Проценты" :value="true"></v-radio>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="user.salary_setting.salary_percent"
                label="%"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" md="2">
              <v-radio label="Оклад + Процент" :value="false"></v-radio>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field v-model="user.rate" label="Сумма"> </v-text-field>
            </v-col>
            <v-col cols="12" md="2" v-if="!user.salary_setting.use_progressive">
              <v-text-field
                v-model="user.salary_setting.percent"
                label="Процент"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-radio-group>
      </v-col>
      <v-col cols="12">
        <v-switch
          v-model="user.salary_setting.use_progressive"
          label="Использовать прогрессивный процент"
        >
        </v-switch>
      </v-col>
      <v-col cols="12">
        <v-switch
          v-model="user.salary_setting.banking"
          label="Учитывать эквайринг"
        >
        </v-switch>
      </v-col>
    </v-row>
    <v-row
      v-if="user.salary_setting.use_progressive"
      class="elevation-4 ma-1 mb-3"
    >
      <v-col
        cols="12"
        v-for="(i, index) in user.salary_setting.percent_arr"
        :key="index"
        class="pl-4 pr-4 pb-0"
      >
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field v-model="i.profit" label="Прибыль"> </v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="i.percent" label="Процент"> </v-text-field>
          </v-col>
          <v-col>
            <v-btn @click="user.salary_setting.percent_arr.splice(index, 1)">
              Удалить
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-btn color="primary" @click="addRule"> Добавить правило </v-btn>
      </v-col>
    </v-row>
    <v-row cols="12" class="elevation-4 ma-1 mb-3">
      <v-col cols="12" md="3">
        <v-text-field
          label="Максимальная сумма зарплаты"
          v-model="user.salary_setting.salary_limit"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="elevation-4 ma-1 mb-3">
      <v-col v-if="user.salary_setting.use_common_costs < 2">
        <v-switch
          v-model="user.salary_setting.includeCosts"
          label="Учитывать расходы"
        ></v-switch>
        <v-text-field
          v-if="user.salary_setting.includeCosts"
          v-model="user.salary_setting.percent_cost"
          label="Процент"
          class="cols-2 ma-1 mb-3"
        >
        </v-text-field>
      </v-col>
      <v-col v-if="!user.salary_setting.useProcent">
        <v-switch
          v-model="user.salary_setting.includeTime"
          label="Учитывать рабочее время"
        ></v-switch>
      </v-col>
      <v-col>
        <h4>Бонус {{ user.bonus }}</h4>
        <v-row no-gutters>
          <v-col>
            <v-slider
              :hint="`${user.bonus}`"
              v-model="user.bonus"
              :max="user.salary_setting.bonus_max"
              min="0"
            ></v-slider>
          </v-col>
          <v-col>
            <v-dialog v-model="bonus_dialog" scrollable max-width="300px">
              <template v-slot:activator="{ on, attrs }">
                <v-icon style="cursor: pointer" v-bind="attrs" v-on="on">
                  mdi-cog-outline
                </v-icon>
              </template>
              <v-card>
                <v-card-title>Настройка бонуса</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 200px">
                  <v-text-field
                    v-model="user.salary_setting.bonus_max"
                    label="Максимальное количество бонусов"
                  >
                  </v-text-field>
                  <v-row>
                    <v-text-field
                      label="Минимальный бонус равен"
                      v-model="user.salary_setting.bonus_min_cost"
                    >
                    </v-text-field>
                  </v-row>
                  <v-row>
                    <v-text-field
                      label="Максимальный бонус равен"
                      v-model="user.salary_setting.bonus_max_cost"
                    >
                    </v-text-field>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="bonus_dialog = false"
                  >
                    Закрыть
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="user.salary_setting.includeTime && !user.salary_setting.useProcent"
    >
      <v-col>
        <h3>Режим работы</h3>
      </v-col>
      <v-col cols="12">
        <v-date-picker cols="12" v-model="dates" multiple dence></v-date-picker>
      </v-col>
    </v-row>
    <v-row class="elevation-4 ma-1 mb-3">
      <v-col cols="12">
        <h3>Договор</h3>
        <v-textarea v-model="user.condition_text"></v-textarea>
      </v-col>

      <v-col>
        <v-btn> Фото </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col>Дата</v-col>
    </v-row>
    <v-row class="elevation-4 ma-1 mb-3">
      <v-col>
        <v-btn @click="updateSetting" color="primary"> Сохранить </v-btn>
      </v-col>
    </v-row>
    <v-row class="elevation-4 ma-1 mb-3">
      <calculate-salary
        :ch_name="user.name"
        :ch_watch="ch_watch"
        :key_aproove="key_aproove"
      ></calculate-salary>
    </v-row>
    <v-row>
      <v-col>
        <h2>История</h2>
      </v-col>
    </v-row>
    <v-row v-for="i in month" :key="i">
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <calculate-salary
          :ch_name="user.name"
          :ch_watch="ch_watch"
          :month="i"
          :key_aproove="key_aproove"
        ></calculate-salary>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" @click="addMonth">
          Показать предыдущий месяц
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import CalculateSalary from "./CalculateSalary.vue";
export default {
  components: { CalculateSalary },
  props: ["id", "key_aproove"],

  data: function() {
    return {
      ch_watch: 0,
      user: {
        salary_setting: {
          bonus_max: 10,
          use_progressive: false,
          percent_arr: [],
          condition_table: [],
        },
      },
      cur_month: "",
      month: [],
      bonus_dialog: false,
      dates: [],
    };
  },
  mounted: function() {
    console.log("ass");
    let date = new Date();
    this.cur_month = parseInt(date.getMonth() + 1);
    axios
      .get("https://old.rem.biz/getFullUser.php", {
        params: {
          id: this.id,
        },
      })
      .then((response) => {
        this.user = response.data[0];
        if (!this.user.salary_setting) {
          this.user.salary_setting = {
            useProcent: false,
            includeTime: false,
            includeCosts: false,
            salary_percent: 0,
            salary_limit: 0,
            percent: 0,
            percent_cost: 0,
            use_common_costs: true,
            bonus_max: 10,
            percent_arr: [],
            use_progressive: false,
          };
        } else {
          this.user.salary_setting = JSON.parse(this.user.salary_setting);
          this.user.salary_setting.bonus_max = parseInt(
            this.user.salary_setting.bonus_max
          )
            ? parseInt(this.user.salary_setting.bonus_max)
            : 10;
          this.user.salary_setting.percent_arr = this.user.salary_setting
            .percent_arr
            ? this.user.salary_setting.percent_arr
            : [];
        }
      });
  },
  methods: {
    addMonth: function() {
      this.cur_month--;
      let month = ("00" + this.cur_month).slice(-2);
      this.month.push(month);
    },
    addRule: function() {
      this.user.salary_setting.percent_arr.push({
        profit: 0,
        percent: 0,
      });
    },
    updateSetting: function() {
      axios
        .post("https://old.rem.biz/updateFullUser.php", {
          name: this.user.name,
          condition_text: this.user.condition_text,
          rate: this.user.rate,
          bonus: this.user.bonus,
          id: this.user.id,
          salary_setting: JSON.stringify(this.user.salary_setting),
        })
        .then(() => {
          this.ch_watch += 1;
        });
    },
  },
};
</script>
