<template>
  <v-container no-gutters>
    <v-row>
      <v-col cols="12" lg="3">
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="from1"
              label="От"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="from"
            @input="menu1 = false"
            :max="to"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" lg="3">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="to1"
              label="До"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="to"
            @input="menu2 = false"
            :max="now"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" lg="3">
        <v-btn color="primary" elevation="2" large @click="getApart"
          >Показать</v-btn
        >
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="col-xl-4 col-lg-6 col-xs-12">
        <apexchart
          width="100%"
          ref="realtimeChart"
          type="pie"
          :options="options"
          :series="series"
        ></apexchart>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="aparts"
          :items-per-page="15"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:footer>
            <v-row no-gutters class="prefooter flex-row flex-xs-column">
              <v-spacer></v-spacer>
              <v-col
                class="col-xl-2 col-lg-2 col-sm-6 col-xs-12 pa-2"
                style="min-width: 200px; width: 150px; max-width: 150px"
              >
                <b
                  >Общая сумма:
                  {{ Math.round((total_job + total_aparts) * 0.98) }}₽</b
                >
              </v-col>
              <v-col
                class="col-xl-2 col-lg-2 col-sm-6 col-xs-12 pa-2"
                style="min-width: 200px; width: 150px; max-width: 150px"
              >
                <b>Запчасти: {{ total_aparts }}₽</b>
              </v-col>
              <v-col
                class="col-xl-2 col-lg-2 col-sm-6 col-xs-12 pa-2"
                style="min-width: 200px; width: 150px; max-width: 150px"
              >
                <b>Работа: {{ total_job }}₽</b>
              </v-col>
              <v-col
                class="col-xl-2 col-lg-2 col-sm-6 col-xs-12 pa-2"
                style="min-width: 200px; width: 150px; max-width: 150px"
              >
                <b>Снятие: {{ total_cost }}₽</b>
              </v-col>

              <v-col
                class="col-xl-2 col-lg-2 col-sm-6 col-xs-12 pa-2"
                style="min-width: 200px; width: 150px; max-width: 150px"
              >
                <b>Итого: {{ total }}₽</b>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import axios from "axios";
export default defineComponent({
  name: "Spares",
  props: ["key_aproove"],
  data: () => ({
    total_aparts: 0,
    total_job: 0,
    total_cost: 0,
    headers: [
      { text: "Квитанция", sortable: false, value: "order", width: "90px" },
      { text: "Поломка", sortable: false, value: "problem", width: "250px" },
      { text: "Аппарат", sortable: false, value: "device", width: "150px" },
      {
        text: "Название детали",
        sortable: false,
        value: "name",
        width: "250px",
      },
      {
        text: "Комментарий",
        sortable: false,
        value: "comment",
        width: "250px",
      },
      { text: "Работа", sortable: false, value: "price_job" },
      { text: "Запчасти", sortable: false, value: "price" },
      { text: "Общая стоимость", sortable: false, value: "price_total" },
      { text: "Мастер", sortable: false, value: "master" },
      { text: "Дата", sortable: false, value: "date" },
    ],
    from: "",
    to: "",
    aparts: [],
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu1: false,
    menu2: false,
    orders: [],
    now: new Date().toISOString().substr(0, 10),
    options: {
      labels: ["За работу", "За запчасти", "Снятие"],
      legend: {
        position: "right",
      },
      responsive: [
        {
          breakpoint: 90000,
          options: {
            legend: {
              position: "right",
            },
          },
        },
        {
          breakpoint: 900,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  }),
  mounted() {
    if (!this.key_aproove) {
      this.$router.push("/");
    }
    let date = new Date();
    this.from =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2);
    this.to =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2);
    this.now =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2);
    this.getApart();
  },
  methods: {
    updateChart: function() {
      this.series = [this.total_job, this.total_aparts, this.total_cost];
      let labels = [];
      labels.push(`За работу ${this.total_job}₽`);
      labels.push(`За запчасти ${this.total_aparts}₽`);
      labels.push(`Снятие ${this.total_cost}₽`);
      this.options = {
        labels: labels,
        legend: {
          position: "right",
        },
        responsive: [
          {
            breakpoint: 90000,
            options: {
              legend: {
                position: "right",
              },
            },
          },
          {
            breakpoint: 900,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
    getApart: function() {
      axios
        .get(`https://old.rem.biz/data.php?from=${this.from}&to=${this.to}`)
        .then((response) => {
          this.total_aparts = 0;
          this.total_job = 0;
          this.aparts = [];
          this.orders = [];
          response.data.forEach((el) => {
            el.price = parseInt(el.price);
            el.price_job = parseInt(el.price_job);
            el.date = new Date(el.date).toLocaleDateString();
            if (!this.orders.includes(el.order)) {
              this.aparts.push(el);
              this.orders.push(el.order);
              this.total_aparts = this.total_aparts + el.price;
              this.total_job = this.total_job + el.price_job;
            } else {
              let ind = this.aparts.findIndex((elm) => elm.order == el.order);
              for (let prop in this.aparts[ind]) {
                if (this.aparts[ind][prop] != el[prop]) {
                  this.aparts[ind][prop] += `; ${el[prop]}`;
                }
              }
            }
          });
          this.updateChart();
        });
      this.total_cost = 0;
      axios
        .get(
          `https://old.rem.biz/collectionTotal.php?from=${this.from}&to=${this.to}`
        )
        .then((response) => {
          this.total_cost = parseInt(response.data[0].total_cost);
          if (!this.total_cost) {
            this.total_cost = 0;
          }
          this.updateChart();
        });
    },
  },
  computed: {
    to1: function() {
      return new Date(this.to).toLocaleDateString();
    },
    from1: function() {
      return new Date(this.from).toLocaleDateString();
    },
    total: function() {
      return this.total_aparts + this.total_job - this.total_cost;
    },
  },
});
</script>
<style>
@media screen and (max-width: 600px) {
  .prefooter .spacer {
    display: none;
  }
}
</style>
