<template>
  <v-container no-gutters>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="aparts"
          item-key="id"
          :items-per-page="15"
          class="elevation-1"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import axios from "axios";
export default defineComponent({
  name: "Spares",
  props: ["key_aproove"],
  data: () => ({
    total_aparts: 0,
    total_job: 0,
    total_cost: 0,
    headers: [
      { text: "Квитанция", sortable: false, value: "order", width: "90px" },
      { text: "Поломка", sortable: false, value: "problem", width: "250px" },
      { text: "Аппарат", sortable: false, value: "device", width: "150px" },
      {
        text: "Комментарий",
        sortable: false,
        value: "comment",
        width: "250px",
      },
      { text: "Мастер", sortable: false, value: "master" },
      { text: "Дата приемки", sortable: false, value: "date" },
    ],
    from: "",
    to: "",
    aparts: [],
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu1: false,
    menu2: false,
    orders: [],
    now: new Date().toISOString().substr(0, 10),
  }),
  mounted() {
    if (!this.key_aproove) {
      this.$router.push("/");
    }
    this.getApart();
  },
  methods: {
    getApart: function() {
      axios.get(`https://old.rem.biz/dataOrders.php`).then((response) => {
        this.aparts = response.data;
        this.aparts.forEach((el) => {
          el.date = new Date(el.date).toLocaleDateString();
        });
      });
    },
  },
});
</script>
<style>
@media screen and (max-width: 600px) {
  .prefooter .spacer {
    display: none;
  }
}
</style>
