<template>
    <v-container>
        <v-row>
            <h2>{{action_name}}</h2>
        </v-row>
        <v-row justify="center">
            <form>
                <v-textfild></v-textfild>
                <v-textfild></v-textfild>
                <v-btn>{{action_action}}</v-btn>
            </form>
        </v-row>
    </v-container>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    data () {
        return{
            action_name: "Вход",
            action_action: "Войти"
        }
    }
})
</script>
