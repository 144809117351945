var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"no-gutters":""}},[_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"От","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.from1),callback:function ($$v) {_vm.from1=$$v},expression:"from1"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"max":_vm.to},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"До","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.to1),callback:function ($$v) {_vm.to1=$$v},expression:"to1"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"max":_vm.now},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}})],1)],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary","elevation":"2","large":""},on:{"click":_vm.getTimeCost}},[_vm._v("Показать")])],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"col-xl-4 col-lg-6 col-xs-12"},[_c('apexchart',{ref:"realtimeChart",attrs:{"width":"100%","type":"pie","options":_vm.options,"series":_vm.series}})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":_vm.current_table == 'aparts' ? 'primary' : 'normal',"block":""},on:{"click":function($event){return _vm.changeTable('aparts')}}},[_vm._v(" Куплено ")])],1),_c('v-col',[_c('v-btn',{attrs:{"color":_vm.current_table == 'job_aparts' ? 'primary' : 'normal',"block":""},on:{"click":function($event){return _vm.changeTable('job_aparts')}}},[_vm._v(" Реализовано ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"flex-sm-column"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.costs,"items-per-page":15,"item-key":"id"},scopedSlots:_vm._u([{key:"item.file",fn:function(ref){
var item = ref.item;
return [(item.file)?_c('v-btn',{on:{"click":function($event){return _vm.openPhoto(item.file)}}},[_vm._v(" Фото ")]):_vm._e()]}},{key:"footer",fn:function(){return [_c('v-row',{staticClass:"prefooter",attrs:{"no-gutters":""}},[_c('v-spacer'),_c('v-col',{staticClass:"pa-2",staticStyle:{"display":"inline-block"}},[_c('div',{staticStyle:{"width":"300px","margin-left":"auto"}},[_c('b',[_vm._v(_vm._s(_vm.result))])])])],1)]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }