var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"no-gutters":""}},[_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"От","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.from1),callback:function ($$v) {_vm.from1=$$v},expression:"from1"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"max":_vm.to},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"До","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.to1),callback:function ($$v) {_vm.to1=$$v},expression:"to1"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"max":_vm.now},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}})],1)],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary","elevation":"2","large":""},on:{"click":_vm.getTimeCost}},[_vm._v("Показать")])],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"col-xl-4 col-lg-6 col-xs-12"},[_c('apexchart',{ref:"realtimeChart",attrs:{"width":"100%","type":"pie","options":_vm.options,"series":_vm.series}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.select_items,"label":"Имя","required":""},on:{"change":_vm.filterPersonals},model:{value:(_vm.filter_item),callback:function ($$v) {_vm.filter_item=$$v},expression:"filter_item"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"flex-sm-column"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"current":"ru","dense":"","headers":_vm.headers,"items":_vm.costs,"item-key":"id","show-select":""},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('v-row',{staticClass:"prefooter",attrs:{"no-gutters":""}},[_c('v-spacer'),_c('v-col',{staticClass:"pa-2",staticStyle:{"display":"inline-block"}},[_c('div',{staticStyle:{"width":"120px","margin-left":"auto"}},[_c('b',[_vm._v("Всего: "+_vm._s(_vm.total_items))])])])],1)]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-row',{staticClass:"flex-xs-column"},[(_vm.selected.length > 0)?_c('v-col',[_c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"col-2 col-sm-12",attrs:{"depressed":"","color":"error","block":""}},'v-btn',attrs,false),on),[_vm._v(" Удалить ")])]}}],null,false,2603427802),model:{value:(_vm.dialog_delete),callback:function ($$v) {_vm.dialog_delete=$$v},expression:"dialog_delete"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Для удаления введите ключ")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Ключ","type":"password","required":"","error-messages":_vm.error},model:{value:(_vm.key),callback:function ($$v) {_vm.key=$$v},expression:"key"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog_delete = false}}},[_vm._v(" Закрыть ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.proove}},[_vm._v(" Подтвердить ")])],1)],1)],1)],1):_vm._e(),(_vm.selected.length == 1)?_c('v-col',[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"col-2 col-xs-12",attrs:{"color":"success","dark":"","block":""}},'v-btn',attrs,false),on),[_vm._v(" Редактировать ")])]}}],null,false,2287791174),model:{value:(_vm.dialog_edit),callback:function ($$v) {_vm.dialog_edit=$$v},expression:"dialog_edit"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Редактировать")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.select_items,"label":"Имя","required":""},model:{value:(_vm.edit_item.name),callback:function ($$v) {_vm.$set(_vm.edit_item, "name", $$v)},expression:"edit_item.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Сумма","required":""},model:{value:(_vm.edit_item.salary),callback:function ($$v) {_vm.$set(_vm.edit_item, "salary", $$v)},expression:"edit_item.salary"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"name":"input-7-1","label":"Комментарий","hint":"Введите текст комменария"},model:{value:(_vm.edit_item.comment),callback:function ($$v) {_vm.$set(_vm.edit_item, "comment", $$v)},expression:"edit_item.comment"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog_edit = false}}},[_vm._v(" Закрыть ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.editItem}},[_vm._v(" Сохранить ")])],1)],1)],1)],1):_vm._e(),_c('v-spacer'),_c('v-spacer'),_c('v-col',{staticClass:"col-lg-2 col-sm-12"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-spacer'),_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","col":"2","block":""}},'v-btn',attrs,false),on),[_vm._v(" Добавить ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Добавить")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.select_items,"label":"Имя","required":""},model:{value:(_vm.new_item.name),callback:function ($$v) {_vm.$set(_vm.new_item, "name", $$v)},expression:"new_item.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Сумма","required":""},model:{value:(_vm.new_item.salary),callback:function ($$v) {_vm.$set(_vm.new_item, "salary", $$v)},expression:"new_item.salary"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Дата","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.new_item.salary_date),callback:function ($$v) {_vm.$set(_vm.new_item, "salary_date", $$v)},expression:"new_item.salary_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu3 = false}},model:{value:(_vm.new_item.salary_date),callback:function ($$v) {_vm.$set(_vm.new_item, "salary_date", $$v)},expression:"new_item.salary_date"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"name":"input-7-1","label":"Комментарий","hint":"Введите текст комменария"},model:{value:(_vm.new_item.comment),callback:function ($$v) {_vm.$set(_vm.new_item, "comment", $$v)},expression:"new_item.comment"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Закрыть ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.addItem}},[_vm._v(" Добавить ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }