<template>
  <v-container no-gutters>
    <v-row v-if="key_aproove">
      <v-col>
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="from"
              label="От"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="from"
            @input="menu1 = false"
            :max="to"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="to"
              label="До"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="to"
            @input="menu2 = false"
            :max="now"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-btn color="primary" elevation="2" large @click="getStatistic"
          >Показать</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-if="key_aproove">
      <v-col>
        <h2>Чистая прибыль: {{ Math.round(profit * 0.98) }}₽</h2>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="key_aproove">
      <v-col class="col-xl-4 col-lg-6 col-xs-12">
        <apexchart
          width="100%"
          ref="realtimeChart"
          type="pie"
          :options="options"
          :series="series"
        ></apexchart>
      </v-col>
    </v-row>
    <v-row v-if="key_aproove">
      <v-col>
        <v-btn color="primary" @click="show_table = !show_table">
          {{ button_text }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="key_aproove && show_table">
      <v-col cols="12">
        <v-simple-table :items="items">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Расход</th>
                <th class="text-left">Сумма</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.title">
                <td>{{ item.title }}</td>
                <td>{{ item.result }}₽</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="!key_aproove">
      <v-col cols="5">
        <v-img width="100%" height="auto" src="/assets/logo.svg"> </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Home",
  props: ["key_aproove"],
  data: function() {
    return {
      show_table: false,
      menu1: false,
      menu2: false,
      items: [],
      from: "",
      to: "",
      now: "",
      result: [],
      total: 0,
      profit: 0,
      costs: 0,
      series: [],
      options: {
        labels: [],
        responsive: [
          {
            breakpoint: 90000,
            options: {
              legend: {
                position: "right",
              },
            },
          },
          {
            breakpoint: 900,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  mounted: function() {
    if (!this.key_aproove) {
      this.$router.push("/");
    }
    let date = new Date();
    this.from =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2);
    this.to =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2);
    this.now =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2);
    this.getStatistic();
  },
  methods: {
    getStatistic: function() {
      axios
        .get("https://old.rem.biz/statistic.php", {
          params: {
            from: this.from,
            to: this.to,
          },
        })
        .then((response) => {
          this.result = [];
          this.total = parseInt(response.data.main[0].total)
            ? parseInt(response.data.main[0].total)
            : 0;
          this.items = [
            {
              title: "Расходы",
              result:
                response.data.collection.reduce((prev, el) => {
                  let amount = parseInt(el.total) ? parseInt(el.total) : 0;
                  return prev + amount;
                }, 0) +
                response.data.costs.reduce((prev, el) => {
                  let amount = parseInt(el.total) ? parseInt(el.total) : 0;
                  return prev + amount;
                }, 0),
            },
            {
              title: "Зарплата",
              result: response.data.salary.reduce((prev, el) => {
                let amount = parseInt(el.total) ? parseInt(el.total) : 0;
                return prev + amount;
              }, 0),
            },
          ];
          this.costs = this.items.reduce((prev, el) => {
            return prev + el.result;
          }, 0);
          this.items.push({
            title: "Доход",
            result: this.total,
          });
          this.updateChart();
        });
    },
    updateChart: function() {
      this.series = [];
      this.profit = this.total - this.costs;
      this.series.push(this.total);
      this.series.push(this.costs);
      let labels = [];
      labels.push(`Доход ${this.total}₽`);
      labels.push(`Расходы ${this.costs}₽`);
      this.options = {
        labels: labels,
        responsive: [
          {
            breakpoint: 90000,
            options: {
              legend: {
                position: "right",
              },
            },
          },
          {
            breakpoint: 900,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
  },
  computed: {
    button_text: function() {
      if (this.show_table) {
        return "Скрыть";
      } else {
        return "Подробнее";
      }
    },
  },
};
</script>
