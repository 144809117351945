import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Spares from '../views/Spares.vue';
import Orders from '../views/Orders.vue';
import Setting from '../views/Setting.vue';
import Personal from '../views/Personal.vue';
import Salary from '../views/Salary.vue';
import Collection from '../views/Collection.vue';
import CalculateSalary from '../views/CalculateSalary.vue';
import Aparts from '../views/Aparts.vue';
import PersonalCard from '../views/PersonalCard.vue'
import Forecast from '../views/Forecast.vue'
import Zadarma from '../views/Zadarma.vue'
import Auth from '../views/Auth.vue'
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    props: true
},
{
    path: '/report-spares',
    name: 'Отчет - запчасти',
    component: Spares,
    props: true
},
{
    path: '/orders',
    name: 'Заказы',
    component: Orders,
    props: true
},
{
    path: '/aparts',
    name: 'Запчасти',
    component: Aparts,
    props: true
},
{
    path: '/costs',
    name: 'Pacxoды',
    component: Collection,
    props: true,
    meta: {
        table_type: 'common_costs'
    }
},
{
    path: '/calculate-salary',
    name: 'Рассчитать зарплату',
    component: CalculateSalary,
    props: true
},
{
    path: '/collection',
    name: 'Снятие',
    component: Collection,
    props: true,
    meta: {
        table_type: 'collection'
    }
},
{
    path: '/setting',
    name: 'Настройки',
    component: Setting,
    props: true,
},
{
    path: '/personal/:id',
    name: 'PersonalCard',
    component: PersonalCard,
    props: true,

},
{
    path: '/personal',
    name: 'Сотрудники',
    component: Personal,
    props: true,
},

{
    path: '/salary',
    name: 'Зарплата',
    component: Salary,
    props: true,
},
{
    path: '/forecast',
    component: Forecast,
    props: true,
},
{
    path: '/zadarma',
    component: Zadarma,
    props: true,
},
{
    path: '/auth',
    component: Auth,
    props: true,
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router