<template>
  <v-container no-gutters>
    <v-col class="col-lg-2 col-sm-12">
      <v-dialog v-model="dialog" persistent max-width="400px">
        <template v-slot:activator="{ on, attrs }">
          <v-spacer></v-spacer>
          <v-btn color="primary" dark v-bind="attrs" v-on="on" col="2" block>
            Добавить сотрудника
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Добавить сотрудника</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Имя"
                    v-model="new_item.name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :items="select_items"
                    label="Должность"
                    required
                    v-model="new_item.position"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Закрыть
            </v-btn>
            <v-btn color="blue darken-1" text @click="addItem">
              Добавить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-row>
      <v-col class="flex-sm-column">
        <v-data-table
          dense
          :headers="headers"
          :items="personal"
          :items-per-page="15"
          v-model="selected"
          item-key="id"
          class="elevation-1"
          show-select
        >
          <template v-slot:[`item.id`]="{ item }">
            <router-link
              :to="{
                name: `PersonalCard`,
                params: { id: item.id, user: item, key_aproove: key_aproove },
              }"
            >
              <v-btn color="primary"> Настройки </v-btn>
            </router-link>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="flex-xs-column">
      <v-col v-if="selected.length > 0">
        <v-dialog v-model="dialog_delete" persistent max-width="400px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              color="error"
              block
              class="col-2 col-sm-12"
              v-bind="attrs"
              v-on="on"
            >
              Удалить
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Для удаления введите ключ</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Ключ"
                      type="password"
                      v-model="key"
                      required
                      :error-messages="error"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog_delete = false">
                Закрыть
              </v-btn>
              <v-btn color="blue darken-1" text @click="proove">
                Подтвердить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col v-if="selected.length == 1">
        <v-dialog v-model="dialog_edit" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success"
              dark
              v-bind="attrs"
              v-on="on"
              block
              class="col-2 col-xs-12"
            >
              Редактировать
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">Редактировать</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Имя"
                      v-model="selected[0].name"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      :items="select_items"
                      label="Должность"
                      required
                      v-model="selected[0].title"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog_edit = false">
                Закрыть
              </v-btn>
              <v-btn color="blue darken-1" text @click="editItem">
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import axios from "axios";

export default defineComponent({
  name: "Personal",
  props: ["key_aproove"],
  data: () => ({
    dialog_delete: false,
    key: "",
    error: [],
    selected: [],
    dialog_edit: false,
    dialog: false,
    new_item: {
      name: "",
      position: "",
      rate: "",
      bonus: "",
      condition: "",
    },
    personal: [],
    selectors: [],
    select_items: [],
    headers: [
      { text: "Имя", sortable: false, value: "name" },
      { text: "Должность", sortable: false, value: "title" },
      { text: "Настройки", sortable: false, value: "id" },
    ],
  }),
  mounted() {
    if (!this.key_aproove) {
      this.$router.push("/");
    }
    this.getPersonal();
    this.getCostTypes();
  },
  methods: {
    proove: function() {
      axios
        .post("https://old.rem.biz/proove.php", {
          key: this.key,
        })
        .then((response) => {
          if (response.data == "ok") {
            this.deleteItems();
            this.key = "";
            this.dialog_delete = false;
          } else {
            this.error = [];
            this.error.push("Неверно");
          }
        });
    },
    getCostTypes: function() {
      axios
        .get(`https://old.rem.biz/costsTypes.php?td=position`)
        .then((response) => {
          this.select_items = [];
          this.selectors = response.data;
          response.data.forEach((el) => {
            this.select_items.push(el.title);
          });
        });
    },
    addItem: function() {
      let pos_id = this.selectors.find(
        (el) => el.title == this.new_item.position
      );
      axios
        .post("https://old.rem.biz/createPersonal.php", {
          name: this.new_item.name,
          pos_id: pos_id.id,
          rate: this.new_item.rate,
          bonus: this.new_item.bonus,
          condition: this.new_item.condition,
        })
        .then((response) => {
          if (response.text != "error") {
            this.personal.push(response.data);
            this.personal[this.personal.length - 1].title = pos_id.title;
            this.new_item = {
              name: "",
              title: "",
              rate: "",
              bonus: "",
              condition: "",
            };
          }
          this.dialog = false;
        });
    },
    editItem: function() {
      let pos_id = this.selectors.find(
        (el) => el.title == this.selected[0].title
      );
      console.log(pos_id);
      axios
        .post("https://old.rem.biz/editPersonal.php", {
          id: this.selected[0].id,
          name: this.selected[0].name,
          rate: this.selected[0].rate,
          bonus: this.selected[0].bonus,
          pos_id: pos_id.id,
          condition: this.selected[0].condition,
        })
        .then((response) => {
          if (response.text != "error") {
            this.dialog_edit = false;
            this.selected = [];
          }
        });
    },
    deleteItems: function() {
      let ids = this.selected.map((el) => {
        return el.id;
      });
      axios
        .get("https://old.rem.biz/deleteCostsType.php", {
          params: {
            to_del: ids,
            table: "personal",
          },
        })
        .then((response) => {
          if (response.data == "ok") {
            ids.forEach((id) => {
              let ind = this.personal.findIndex((el) => {
                return el.id == id;
              });

              this.personal.splice(ind, 1);
            });
            this.selected = [];
          }
        });
    },
    getPersonal: function() {
      axios.get(`https://old.rem.biz/getPersonal.php`).then((response) => {
        this.personal = response.data;
      });
    },
  },
});
</script>
<style>
.prefooter {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
</style>
