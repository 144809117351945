<template>
  <div id="app">
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" app>
        <router-link to="/">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title"> Главная </v-list-item-title>
              <v-list-item-subtitle>
                основные пункты меню
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </router-link>

        <v-divider></v-divider>

        <v-list dense nav>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            @click="loc(item.to)"
            v-show="item.allow"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div style="height: 90px"></div>
      </v-navigation-drawer>

      <v-app-bar app>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-col v-if="!key_aprooved">
          <v-dialog v-model="dialog_proove" persistent max-width="400px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" elevation="2" v-bind="attrs" v-on="on"
                >Ввести ключ</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Введите ключ</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Ключ"
                        type="password"
                        v-model="key"
                        required
                        :error-messages="error"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-checkbox
                        v-model="key_save"
                        label="Сохранить ключ в этом браузере"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog_proove = false"
                >
                  Закрыть
                </v-btn>
                <v-btn color="blue darken-1" text @click="proove">
                  Подтвердить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-app-bar>

      <v-main>
        <router-view :key_aproove="key_aprooved" :table_type="table_type" />
      </v-main>
    </v-app>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    drawer: null,
    title: "Сервис центр",
    error: "",
    key: "",
    table_type: "",
    key_aprooved: false,
    key_save: "",
    dialog_proove: false,
    items: [
      {
        title: "Заказы",
        to: "/orders",
        allow: false,
      },
      {
        title: "Выдача",
        to: "/report-spares",
        allow: false,
      },
      {
        title: "Снятие",
        to: "/collection",
        allow: false,
        table_type: "collection",
      },
      {
        title: "Расходы",
        to: "/costs",
        allow: false,
        table_type: "common_costs",
      },
      {
        title: "Запчасти",
        to: "/aparts",
        allow: false,
      },
      { title: "Сотрудники", to: "/personal", allow: false },
      { title: "Зарплата", to: "/salary", allow: false },
      { title: "Рассчитать зарплату", to: "/calculate-salary", allow: false },
      { title: "Прогноз прибыли", to: "/forecast", allow: false },
      { title: "Настройки", to: "/setting", allow: false },
      {
        title: "Zadarma",
        to: "/zadarma",
        allow: false,
      },
    ],
    right: null,
  }),
  mounted: function() {
    this.key = localStorage.getItem("key");
    if (this.key) {
      this.key_save = true;
      this.proove();
    }
  },
  methods: {
    proove: function() {
      axios
        .post("https://old.rem.biz/proove.php", {
          key: this.key,
        })
        .then((response) => {
          if (response.data == "ok") {
            this.key_aprooved = true;
            if (this.key_save) {
              localStorage.setItem("key", this.key);
              this.key = "";
            }
            this.dialog_proove = false;
            this.items.forEach((el) => {
              el.allow = true;
            });
          } else {
            this.key_aprooved = false;
            this.error = [];
            this.error.push("Неверно");
          }
        });
    },
    loc: function(path) {
      let item = this.items.find((a) => a.to == path);
      this.table_type = item.table_type;
      if (item.allow) {
        this.title = item.title;
        this.$router.push(path);
      }
    },
  },
};
</script>
<style less scoped>
@media screen and (max-width: 600px) {
  .v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
    display: block !important;
    margin-bottom: 20px;
  }
  .v-data-table.elevation-1 {
    box-shadow: none !important;
  }
}
</style>
