import Vue from 'vue'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from './plugins/axios'
import i18n from './i18n'
import VueApexCharts from 'vue-apexcharts'
Vue.config.productionTip = false
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
new Vue({
    vuetify,
    router,
    axios,
    i18n,
    render: h => h(App)
}).$mount('#app')