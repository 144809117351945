<template>
  <v-container no-gutters>
    <v-row>
      <v-col>
        <p class="headline">Прибыль составит: {{ profit1 }}</p>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col md="3">
        <p class="headline">Расходы на сумму:</p>
      </v-col>
      <v-col md="3">
        <v-text-field v-model="cost"> </v-text-field>
      </v-col>
    </v-row>
    <v-row v-for="el in main_personal" :key="el.id" align="center">
      <v-col md="2">
        {{ el.name }}
      </v-col>
      <v-col md="3">
        <v-text-field v-model="el.profit" @input="calculateProfit">
        </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";

export default {
  props: ["key_aproove"],
  data: () => {
    return {
      personal: [],
      profit: 0,
      cost_percent: 0,
      profit1: 0,
      cost: 0,
    };
  },
  mounted: function() {
    if (!this.key_aproove) {
      this.$router.push("/");
    }
    axios.get("https://old.rem.biz/getPersonalMaster.php").then((response) => {
      this.personal = response.data;
      this.personal.forEach((el) => {
        el.salary_setting = JSON.parse(el.salary_setting);
        el.profit = 0;
        el.salary_setting.use_common_costs = parseInt(
          el.salary_setting.use_common_costs
        );
      });
      this.personal.sort((a, b) => {
        if (
          a.salary_setting.use_common_costs > b.salary_setting.use_common_costs
        ) {
          return 1;
        }
        if (
          a.salary_setting.use_common_costs < b.salary_setting.use_common_costs
        ) {
          return -1;
        }
        return 0;
      });
    });
  },
  methods: {
    calculateProfit: function() {
      this.profit = 0;
      this.main_personal.forEach((el) => {
        el.profit = parseInt(el.profit) ? parseInt(el.profit) : 0;
        this.profit += parseInt(el.profit);
      });
      let tot_sal = 0;
      this.personal.forEach((el) => {
        el.salary = this.calculate(el);
        el.salary = parseInt(el.salary) ? parseInt(el.salary) : 0;
        tot_sal += el.salary;
      });

      this.profit1 = this.profit - parseInt(this.cost) - tot_sal;
    },
    calculate: function(user) {
      let salary = 0;
      let setting = user.salary_setting;
      if (!setting.useProcent) {
        salary = user.rate;
      }
      let proc = this.computeProcent(user);
      let cost = this.computeCost(user);
      switch (parseInt(setting.use_common_costs)) {
        case 0:
          user.salary = salary + ((user.profit - cost) * proc) / 100;
          break;
        default:
          user.salary = salary + ((this.profit - cost) * proc) / 100;
      }
      if (
        setting.salary_limit &&
        parseInt(setting.salary_limit) < user.salary
      ) {
        user.salary = parseInt(setting.salary_limit);
      }
      return user.salary;
    },
    computeCost: function(user) {
      user.salary_setting.percent_cost = parseInt(
        user.salary_setting.percent_cost
      )
        ? parseInt(user.salary_setting.percent_cost)
        : 0;
      let common_salary = this.personal.filter((el) => {
        return (
          user.salary_setting.use_common_costs >
          el.salary_setting.use_common_costs
        );
      });
      common_salary = common_salary.reduce((el, prev) => {
        return el.salary + prev;
      }, 0);
      if (!user.salary_setting.includeCosts) {
        return common_salary;
      } else {
        return (
          (this.cost * user.salary_setting.percent_cost) / 100 + common_salary
        );
      }
    },
    computeProcent: function(user) {
      let if_use = -1;
      let total_profit =
        user.salary_setting.use_common_costs == 0 ? user.profit : this.profit;
      if (user.salary_setting.use_progressive) {
        for (let i = 0; i < user.salary_setting.percent_arr.length; i++) {
          if (
            parseInt(user.salary_setting.percent_arr[i].profit) <
            parseInt(total_profit)
          ) {
            if_use = i;
          }
        }
        if (if_use >= 0) {
          if (user.salary_setting.percent_arr.length == 2 && if_use == 0) {
            let dif_per =
              user.salary_setting.percent_arr[1].percent -
              user.salary_setting.percent_arr[0].percent;
            let dif_sal =
              user.salary_setting.percent_arr[1].profit -
              user.salary_setting.percent_arr[0].profit;
            let a = total_profit - user.salary_setting.percent_arr[0].profit;
            let perc = (a / dif_sal) * dif_per;
            return parseInt(user.salary_setting.percent_arr[0].percent) + perc;
          } else {
            return parseInt(user.salary_setting.percent_arr[if_use].percent);
          }
        }
      }
      if (user.salary_setting.useProcent) {
        return parseInt(user.salary_setting.salary_percent)
          ? parseInt(user.salary_setting.salary_percent)
          : 0;
      } else {
        return parseInt(user.salary_setting.salary_percent)
          ? parseInt(user.salary_setting.salary_percent)
          : 0;
      }
    },
  },

  computed: {
    main_personal: function() {
      return this.personal.filter((el) => {
        return el.pos_title == "Мастер";
      });
    },
    // cost: function () {
    //     return parseInt(this.profit)*parseInt(this.cost_percent)/100;
    // }
  },
};
</script>
