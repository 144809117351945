var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-lg-2 col-sm-12"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-spacer'),_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","col":"2","block":""}},'v-btn',attrs,false),on),[_vm._v(" Добавить сотрудника ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Добавить сотрудника")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Имя","required":""},model:{value:(_vm.new_item.name),callback:function ($$v) {_vm.$set(_vm.new_item, "name", $$v)},expression:"new_item.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.select_items,"label":"Должность","required":""},model:{value:(_vm.new_item.position),callback:function ($$v) {_vm.$set(_vm.new_item, "position", $$v)},expression:"new_item.position"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Закрыть ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.addItem}},[_vm._v(" Добавить ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"flex-sm-column"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.personal,"items-per-page":15,"item-key":"id","show-select":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
              name: "PersonalCard",
              params: { id: item.id, user: item, key_aproove: _vm.key_aproove },
            }}},[_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" Настройки ")])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-row',{staticClass:"flex-xs-column"},[(_vm.selected.length > 0)?_c('v-col',[_c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"col-2 col-sm-12",attrs:{"depressed":"","color":"error","block":""}},'v-btn',attrs,false),on),[_vm._v(" Удалить ")])]}}],null,false,2603427802),model:{value:(_vm.dialog_delete),callback:function ($$v) {_vm.dialog_delete=$$v},expression:"dialog_delete"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Для удаления введите ключ")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Ключ","type":"password","required":"","error-messages":_vm.error},model:{value:(_vm.key),callback:function ($$v) {_vm.key=$$v},expression:"key"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog_delete = false}}},[_vm._v(" Закрыть ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.proove}},[_vm._v(" Подтвердить ")])],1)],1)],1)],1):_vm._e(),(_vm.selected.length == 1)?_c('v-col',[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"col-2 col-xs-12",attrs:{"color":"success","dark":"","block":""}},'v-btn',attrs,false),on),[_vm._v(" Редактировать ")])]}}],null,false,2287791174),model:{value:(_vm.dialog_edit),callback:function ($$v) {_vm.dialog_edit=$$v},expression:"dialog_edit"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Редактировать")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Имя","required":""},model:{value:(_vm.selected[0].name),callback:function ($$v) {_vm.$set(_vm.selected[0], "name", $$v)},expression:"selected[0].name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.select_items,"label":"Должность","required":""},model:{value:(_vm.selected[0].title),callback:function ($$v) {_vm.$set(_vm.selected[0], "title", $$v)},expression:"selected[0].title"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog_edit = false}}},[_vm._v(" Закрыть ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.editItem}},[_vm._v(" Сохранить ")])],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }