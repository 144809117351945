<template>
  <v-container no-gutters>
    <v-row>
      <v-col>
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="from"
              label="От"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="from"
            @input="menu1 = false"
            :max="to"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="to"
              label="До"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="to"
            @input="menu2 = false"
            :max="now"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-btn color="primary" elevation="2" @click="getCalls" large
          >Показать</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col class="flex-sm-column">
        <v-data-table
          current="ru"
          dense
          :headers="headers"
          :items="calls"
          :items-per-page="15"
          item-key="id"
          class="elevation-1"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  name: "Zadarma",
  props: ["key_aproove"],
  data() {
    return {
      menu1: false,
      menu2: false,
      from: "",
      to: "",
      now: "",
      calls: [],
      headers: [
        {
          text: "ID",
          sortable: false,
          value: "id",
        },
        {
          text: "Имя",
          sortable: false,
          value: "name",
        },
        {
          text: "Квитанции",
          sortable: false,
          value: "orders",
        },
        {
          text: "Телефон",
          sortable: false,
          value: "phone",
        },
        {
          text: "Время звонка",
          sortable: false,
          value: "call_start",
        },
        {
          text: "Длительность",
          sortable: false,
          value: "duration",
        },
        {
          text: "Ответили",
          sortable: true,
          value: "is_answered",
        },
      ],
    };
  },
  mounted() {
    if (!this.key_aproove) {
      this.$router.push("/");
    }
    let date = new Date();
    this.from =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2);
    this.to =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2);
    this.now =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2);
    this.getCalls();
  },
  methods: {
    getCalls() {
      axios
        .get(
          `https://old.rem.biz/zadarmaProxy.php?from=${this.from}&to=${this.to}`
        )
        .then((response) => {
          this.calls = [];
          response.data.forEach((el, ind) => {
            this.calls.push(el);
            this.calls[ind].is_answered = el.is_answered ? "Да" : "Нет";
          });
        });
    },
  },
};
</script>
