<template>
    <v-container>
        <div v-for="(sets,index) in settings" :key="index">
            <v-row>
                <v-col>
                    <h2>{{sets.title}}</h2>
                </v-col>    
            </v-row>
            <v-row>
                <v-col>
                    <v-chip
                        class="ma-2"
                        close
                        color="red"
                        text-color="white"
                        @click:close="deleteCostsType(type.id,index)"
                        v-for="type in sets.types"
                        :key="type.id"
                    >
                        {{type.title}}
                    </v-chip>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field
                    :label="sets.inp_label"
                    v-model="sets.new_type"
                    hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-btn
                    color="primary"
                    elevation="2"
                    @click = "createCost(index)"
                    >Добавить</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-divider></v-divider>
            </v-row>
        </div>
    </v-container>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { defineComponent } from '@vue/composition-api'
import axios from 'axios'
export default {
    props: ['key_aproove'],
    data ( ) {
        return {
            settings: [
                {
                    title: 'Виды расходов',
                    types: [],
                    new_type: '',
                    urlDelete: 'deleteCostsType.php',
                    urlCreate: 'createCostTypes.php',
                    urlGet: 'CostsTypes.php',
                    table: 'costs_types',
                    inp_label: "Новый тип расходов"
                },
                {
                    title: 'Должности',
                    types: [],
                    new_type: '',
                    urlDelete: 'deleteCostsType.php',
                    urlCreate: 'createCostsTypes.php',
                    urlGet: 'costsTypes.php',
                    table: 'position',
                    inp_label: "Новая должность"
                }
            ]
        }
    },
    mounted () {
        if (!this.key_aproove) {
            this.$router.push('/');
        }
        this.settings.forEach(sets => {
            axios.get(`costsTypes.php`,{
                params: {
                    td: sets.table
                }
            })
                .then(response=> {
                    sets.types = response.data;
            });
        });
    },
    methods: {
        deleteCostsType: function (id,index) {
            let ind = this.settings[index].types.findIndex(el=> el.id==id);
            this.settings[index].types.splice(ind,1);
            axios.get('/deleteCostsType.php',{
                params: {
                    to_del: [id],
                    table: this.settings[index].table
                }
            })
        },
        createCost: function (index) {
            if (this.settings[index].new_type.length == 0){
                return
            }
            axios.post('createCostTypes.php',{
                    title: this.settings[index].new_type,
                    table: this.settings[index].table
                
            }).then(response => {
                this.settings[index].types.push(response.data);
                this.settings[index].new_type = ''
            })
        }
    }
}
</script>