<template>
  <v-container no-gutters>
    <v-row>
      <v-col>
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="from1"
              label="От"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="from"
            @input="menu1 = false"
            :max="to"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="to1"
              label="До"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="to"
            @input="menu2 = false"
            :max="now"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-btn color="primary" elevation="2" large @click="getTimeCost"
          >Показать</v-btn
        >
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="col-xl-4 col-lg-6 col-xs-12">
        <apexchart
          width="100%"
          ref="realtimeChart"
          type="pie"
          :options="options"
          :series="series"
        ></apexchart>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          :color="current_table == 'aparts' ? 'primary' : 'normal'"
          block
          @click="changeTable('aparts')"
        >
          Куплено
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          :color="current_table == 'job_aparts' ? 'primary' : 'normal'"
          block
          @click="changeTable('job_aparts')"
        >
          Реализовано
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="flex-sm-column">
        <v-data-table
          dense
          :headers="headers"
          :items="costs"
          :items-per-page="15"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:[`item.file`]="{ item }">
            <v-btn v-if="item.file" @click="openPhoto(item.file)"> Фото </v-btn>
          </template>
          <template v-slot:footer>
            <v-row no-gutters class="prefooter">
              <v-spacer></v-spacer>
              <v-col class="pa-2" style="display: inline-block">
                <div style="width: 300px; margin-left: auto">
                  <b>{{ result }}</b>
                </div>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import axios from "axios";

export default defineComponent({
  name: "Aparts",
  props: ["key_aproove"],
  data: () => ({
    file: "",
    current_table: "",
    photo: "",
    photo_dialog: false,
    result: "",
    totals: {
      total: 0,
    },
    aparts: [],
    job_aparts: [],
    total_items: 0,

    headers: [
      { text: "Квитанция", sortable: false, value: "order" },
      { text: "Дата", sortable: false, value: "date", width: "150px" },
      { text: "Комментарий", sortable: false, value: "comment" },
      { text: "Стоимость", sortable: false, value: "price", width: "150px" },
      { text: "Файл", sortable: false, value: "file" },
    ],
    costs: [],
    from: "",
    to: "",
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu1: false,
    menu2: false,
    now: new Date().toISOString().substr(0, 10),
    series: [],
    options: {
      labels: [],
      legend: {
        position: "right",
      },
      responsive: [
        {
          breakpoint: 900,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  }),

  mounted() {
    if (!this.key_aproove) {
      this.$router.push("/");
    }
    let date = new Date();
    this.from =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2);
    this.to =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2);
    this.now =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2);
    this.getTimeCost();
  },
  methods: {
    openPhoto: function(path) {
      this.photo = path;
      this.photo_dialog = true;
    },
    calcStat: function() {
      let labels = [];
      this.series = [];
      this.series.push(
        this.aparts.reduce((prev, el) => {
          return prev + el.price;
        }, 0)
      );
      labels.push(`Потрачено ${this.series[0]}₽`);
      this.series.push(
        this.job_aparts.reduce((prev, el) => {
          return prev + el.price;
        }, 0)
      );
      labels.push(`Реализовано ${this.series[1]}₽`);
      labels.push(`Разница ${this.difference}₽`);
      this.options = {
        labels: labels,
        responsive: [
          {
            breakpoint: 90000,
            options: {
              legend: {
                position: "right",
              },
            },
          },
          {
            breakpoint: 900,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
    changeTable: function(table) {
      this.current_table = table;
      this.costs = this[table];
      let result = this.costs.reduce((prev, el) => {
        return prev + el.price;
      }, 0);
      switch (table) {
        case "aparts":
          this.result = `Потрачено на детали ${result}₽`;
          break;
        case "job_aparts":
          this.result = `Реализовано на ${result}₽`;
          break;
      }
    },
    getTimeCost: function() {
      axios
        .get(
          `https://old.rem.biz/getApartStatistic.php?from=${this.from}&to=${this.to}&table=${this.table_type}`
        )
        .then((response) => {
          this.job_aparts = response.data.main;
          this.aparts = response.data.collection;
          this.aparts = this.aparts.sort((a, b) => {
            if (a.date == b.date) {
              return 0;
            }
            return a.date < b.date ? 1 : -1;
          });
          this.aparts.forEach((el) => {
            el.date = new Date(el.date).toLocaleDateString();
          });

          this.job_aparts.forEach((el) => {
            el.price = parseInt(el.price) ? parseInt(el.price) : 0;
            el.date = new Date(el.date).toLocaleDateString();
          });
          this.changeTable("aparts");
          this.calcStat();
        });
    },
  },
  computed: {
    difference: function() {
      return this.series[1] - this.series[0];
    },
    edit_item: function() {
      return this.selected[0];
    },
    to1: function() {
      return new Date(this.to).toLocaleDateString();
    },
    from1: function() {
      return new Date(this.from).toLocaleDateString();
    },
    total: function() {
      return this.total_aparts + this.total_job;
    },
  },
  watch: {
    $route: function() {
      this.table_type = this.$route.meta.table_type;
      this.new_item.table_type = this.table_type;
      this.getTimeCost();
      this.getCostTypes();
    },
  },
});
</script>
<style>
.prefooter {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
.close_icon {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 100;
}
</style>
