<template>
  <v-container no-gutters>
    <v-row v-if="!ch_name">
      <v-col cols="3" xs="12">
        <v-select
          v-model="choised_personal"
          :items="personal"
          label="Выбрать сотрудника"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="!ch_name">
      <v-col>
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="from"
              label="От"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="from"
            @input="menu1 = false"
            :max="to"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="to"
              label="До"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="to"
            @input="menu2 = false"
            :max="now"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-btn color="primary" elevation="2" large @click="choiseperonal"
          >Показать</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-if="resp">
      <v-col cols="12" v-if="!ch_name">
        Выполнил заказов: {{ resp.total_order }}
      </v-col>
      <v-col cols="12" v-if="!ch_name">
        Выполнил работ на сумму: {{ resp.total_profit }}₽
      </v-col>
      <v-divider></v-divider>
      <v-col cols="12">
        Учитывается эквайринг: {{ banking ? `${banking}₽` : "Нет" }}
      </v-col>
      <v-col cols="12">
        Итого зарплата составляет: {{ result + resp.total_salary }}₽
      </v-col>
      <v-col cols="12"> Бонусов: {{ bonus_result }} </v-col>
      <v-col cols="12"> Выплачено мастеру: {{ resp.total_salary }}₽ </v-col>
      <v-col cols="12">
        Осталось выплатить {{ getMon }}: {{ result + bonus_result }}₽
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";

export default {
  name: "CalculateSalary",

  props: ["ch_name", "ch_watch", "month", "key_aproove"],
  data: () => {
    return {
      personal: [],
      masters: [],
      choised_personal: "",
      from: "",
      to: "",
      now: "",
      menu1: false,
      menu2: false,
      resp: false,
      salary_setting: {},
      includeCosts: 0,
      total_cost: 0,
      cost_per_master: 0,
      other_salary: 0,
    };
  },
  mounted: function() {
    if (!this.key_aproove) {
      this.$router.push("/");
    }
    let date = new Date();
    let month;
    let last_day;
    if (this.month) {
      month = this.month;
      let date1 = new Date(date.getFullYear(), this.month, 0);
      last_day = ("00" + date1.getDate()).slice(-2);
    } else {
      month = ("00" + (date.getMonth() + 1)).slice(-2);
      last_day = ("00" + date.getDate()).slice(-2);
    }
    this.from = date.getFullYear() + "-" + month + "-01";
    this.to = date.getFullYear() + "-" + month + "-" + last_day;
    this.now =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2);
    axios.get("https://old.rem.biz/getPersonalMaster.php").then((response) => {
      this.masters = response.data;
      this.personal = response.data.map((el) => el.name);
      this.choised_personal = this.personal[0];
      if (this.ch_name) {
        this.choised_personal = this.ch_name;
        this.choiseperonal();
      }
    });
  },
  methods: {
    getCostPerMaster: function() {
      let params = {
        from: this.from,
        to: this.to,
      };
      if (parseInt(this.salary_setting.use_common_costs) < 2) {
        params.exclude = "Оборудование";
        console.log(this.salary_setting.use_common_costs);
      }
      axios
        .get("https://old.rem.biz/statistic.php", {
          params: params,
        })
        .then((response) => {
          this.total_cost = response.data.collection.reduce((prev, el) => {
            let amount = parseInt(el.total) ? parseInt(el.total) : 0;
            return prev + amount;
          }, 0);
          this.total_cost += response.data.costs.reduce((prev, el) => {
            let amount = parseInt(el.total) ? parseInt(el.total) : 0;
            return prev + amount;
          }, 0);
          if (this.salary_setting.use_common_costs) {
            this.resp.total_profit = response.data.main[0].total;
            this.total_cost += parseInt(response.data.salary[0].total)
              ? parseInt(response.data.salary[0].total)
              : 0;
          }

          if (parseInt(this.salary_setting.use_common_costs) == 2) {
            this.resp.total_profit -= this.total_cost;
            this.total_cost = 0;
          }
          this.calculate();
        });
    },
    choiseperonal: function() {
      let master_id = this.masters.find(
        (el) => el.name == this.choised_personal
      );

      let name = this.choised_personal;
      axios
        .get("https://old.rem.biz/getMasterStatistic.php", {
          params: {
            name: name,
            id: master_id.id,
            from: this.from,
            to: this.to,
          },
        })
        .then((response) => {
          this.resp = response.data;

          this.salary_setting = JSON.parse(this.resp.salary_setting);
          this.getCostPerMaster();
        });
    },
    calculate: function() {
      let if_use = -1;
      if (this.salary_setting.use_progressive) {
        for (let i = 0; i < this.salary_setting.percent_arr.length; i++) {
          if (
            parseInt(this.salary_setting.percent_arr[i].profit) <
            parseInt(this.resp.total_profit)
          ) {
            if_use = i;
          }
        }
        if (if_use < 0) {
          this.salary_setting.percent = 0;
        } else {
          if (this.salary_setting.percent_arr.length == 2 && if_use == 0) {
            let dif_per =
              this.salary_setting.percent_arr[1].percent -
              this.salary_setting.percent_arr[0].percent;
            let dif_sal =
              this.salary_setting.percent_arr[1].profit -
              this.salary_setting.percent_arr[0].profit;
            let a =
              this.resp.total_profit -
              this.salary_setting.percent_arr[0].profit;
            let perc = (a / dif_sal) * dif_per;

            this.salary_setting.percent =
              parseInt(this.salary_setting.percent_arr[0].percent) + perc;
            this.salary_setting.salary_percent =
              parseInt(this.salary_setting.percent_arr[0].percent) + perc;
          } else {
            this.salary_setting.percent = this.salary_setting.percent_arr[
              if_use
            ].percent;
            this.salary_setting.salary_percent = this.salary_setting.percent_arr[
              if_use
            ].percent;
          }
        }
      }

      this.salary_setting.percent = parseInt(this.salary_setting.percent)
        ? parseInt(this.salary_setting.percent)
        : 0;
      this.salary_setting.salary_percent = parseInt(
        this.salary_setting.salary_percent
      )
        ? parseInt(this.salary_setting.salary_percent)
        : 0;
      this.resp.total_profit = parseInt(this.resp.total_profit)
        ? parseInt(this.resp.total_profit)
        : 0;
      this.resp.total_salary = parseInt(this.resp.total_salary)
        ? parseInt(this.resp.total_salary)
        : 0;
      this.cost_per_master = 0;
      this.salary_setting.percent_cost = parseInt(
        this.salary_setting.percent_cost
      )
        ? this.salary_setting.percent_cost
        : 0;
      this.cost_per_master =
        (this.total_cost * this.salary_setting.percent_cost) / 100;
    },
  },
  computed: {
    getMon: function() {
      let month = [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
      ];
      return month[parseInt(this.month) - 1];
    },
    banking() {
      if (this.salary_setting.banking) {
        return Math.round(this.resp.total_profit * 0.021);
      } else {
        return 0;
      }
    },
    result: function() {
      this.calculate();
      let res = 0;
      let res1;
      let limit = parseInt(this.salary_setting.salary_limit)
        ? parseInt(this.salary_setting.salary_limit)
        : 0;
      const profit = this.resp.total_profit - this.banking;
      if (this.salary_setting.useProcent) {
        if (!this.salary_setting.includeCosts) {
          res1 = (profit * this.salary_setting.salary_percent) / 100;
          if (res1 > limit && limit > 0) {
            res1 = limit;
          }
          res = res1 - this.resp.total_salary;
        } else {
          res1 =
            ((profit - this.cost_per_master) *
              this.salary_setting.salary_percent) /
            100;
          if (res1 > limit && limit > 0) {
            res1 = limit;
          }
          res = res1 - this.resp.total_salary;
        }
      } else {
        let rate = parseInt(this.resp.rate) ? parseInt(this.resp.rate) : 0;
        let remiander = profit - rate;
        remiander = !this.salary_setting.includeCosts
          ? remiander
          : remiander - this.cost_per_master;
        remiander = remiander > 0 ? remiander : 0;
        res1 = rate + (remiander * this.salary_setting.percent) / 100;
        if (res1 > limit && limit > 0) {
          res1 = limit;
        }
        res =
          rate +
          (remiander * this.salary_setting.percent) / 100 -
          this.resp.total_salary;
      }

      return Math.round(res);
    },
    bonus_result: function() {
      let max_cost = parseInt(this.salary_setting.bonus_max_cost)
        ? parseInt(this.salary_setting.bonus_max_cost)
        : 30000;
      let min_cost = parseInt(this.salary_setting.bonus_min_cost)
        ? parseInt(this.salary_setting.bonus_min_cost)
        : 1000;
      let max = parseInt(this.salary_setting.bonus_max)
        ? parseInt(this.salary_setting.bonus_max)
        : 10;
      let res = this.resp.bonus * ((max_cost - min_cost) / (max - 1));
      res = parseInt(res) ? parseInt(res) : 0;
      return Math.round(res);
    },
  },
  watch: {
    ch_watch: function() {
      this.choised_personal = this.ch_name;
      this.choiseperonal();
    },
    ch_name: function() {
      this.choised_personal = this.ch_name;
      this.choiseperonal();
    },
  },
};
</script>
