<template>
  <v-container no-gutters>
    <v-row>
      <v-col>
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="from1"
              label="От"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="from"
            @input="menu1 = false"
            :max="to"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="to1"
              label="До"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="to"
            @input="menu2 = false"
            :max="now"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-btn color="primary" elevation="2" large @click="getTimeCost"
          >Показать</v-btn
        >
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="col-xl-4 col-lg-6 col-xs-12">
        <apexchart
          width="100%"
          ref="realtimeChart"
          type="pie"
          :options="options"
          :series="series"
        ></apexchart>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-select
          :items="select_items"
          label="Имя"
          required
          @change="filterPersonals"
          v-model="filter_item"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="flex-sm-column">
        <v-data-table
          current="ru"
          dense
          :headers="headers"
          :items="costs"
          v-model="selected"
          item-key="id"
          class="elevation-1"
          show-select
        >
          <template v-slot:footer>
            <v-row no-gutters class="prefooter">
              <v-spacer></v-spacer>
              <v-col class="pa-2" style="display: inline-block">
                <div style="width: 120px; margin-left: auto">
                  <b>Всего: {{ total_items }}</b>
                </div>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="flex-xs-column">
      <v-col v-if="selected.length > 0">
        <v-dialog v-model="dialog_delete" persistent max-width="400px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              color="error"
              block
              class="col-2 col-sm-12"
              v-bind="attrs"
              v-on="on"
            >
              Удалить
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Для удаления введите ключ</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Ключ"
                      type="password"
                      v-model="key"
                      required
                      :error-messages="error"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog_delete = false">
                Закрыть
              </v-btn>
              <v-btn color="blue darken-1" text @click="proove">
                Подтвердить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col v-if="selected.length == 1">
        <v-dialog v-model="dialog_edit" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success"
              dark
              v-bind="attrs"
              v-on="on"
              block
              class="col-2 col-xs-12"
            >
              Редактировать
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">Редактировать</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      :items="select_items"
                      label="Имя"
                      required
                      v-model="edit_item.name"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Сумма"
                      v-model="edit_item.salary"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      name="input-7-1"
                      label="Комментарий"
                      v-model="edit_item.comment"
                      hint="Введите текст комменария"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog_edit = false">
                Закрыть
              </v-btn>
              <v-btn color="blue darken-1" text @click="editItem">
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-spacer> </v-spacer>
      <v-spacer> </v-spacer>
      <v-col class="col-lg-2 col-sm-12">
        <v-dialog v-model="dialog" persistent max-width="400px">
          <template v-slot:activator="{ on, attrs }">
            <v-spacer></v-spacer>
            <v-btn color="primary" dark v-bind="attrs" v-on="on" col="2" block>
              Добавить
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">Добавить</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      :items="select_items"
                      label="Имя"
                      required
                      v-model="new_item.name"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Сумма"
                      v-model="new_item.salary"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="new_item.salary_date"
                        label="Дата"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="new_item.salary_date"
                      @input="menu3 = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-col cols="12">
                    <v-textarea
                      name="input-7-1"
                      label="Комментарий"
                      v-model="new_item.comment"
                      hint="Введите текст комменария"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Закрыть
              </v-btn>
              <v-btn color="blue darken-1" text @click="addItem">
                Добавить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import axios from "axios";

export default defineComponent({
  name: "Salary",
  props: ["key_aproove"],
  data: () => ({
    dialog_delete: false,
    unfilter: [],
    key: "",
    filter_item: "",
    error: [],
    totals: {
      total: 0,
    },
    total_items: 0,
    selected: [],
    dialog_edit: false,
    dialog: false,
    new_item: {
      name: "",
      salary: 0,
      comment: "",
    },
    personal: [],
    select_items: [],
    headers: [
      { text: "Имя", sortable: false, value: "name" },
      { text: "Сумма", sortable: false, value: "salary" },
      { text: "Должность", sortable: false, value: "position" },
      { text: "Комментарий", sortable: false, value: "comment" },
      { text: "Дата", sortable: false, value: "date", width: "150px" },
    ],
    costs: [],
    from: "",
    to: "",
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu1: false,
    menu2: false,
    menu3: false,
    now: new Date().toISOString().substr(0, 10),
    series: [],
    options: {
      labels: [],
      legend: {
        position: "right",
      },
      responsive: [
        {
          breakpoint: 900,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  }),
  mounted() {
    if (!this.key_aproove) {
      this.$router.push("/");
    }
    let date = new Date();
    this.from =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2);
    this.to =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2);
    this.now =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2);
    this.new_item.salary_date = this.now;
    this.getTimeCost();
    this.getCostTypes();
  },
  methods: {
    filterPersonals: function() {
      this.costs = this.unfilter.filter((el) => el.name == this.filter_item);
      this.total_items = this.costs.reduce((prev, res) => {
        return prev + res.salary;
      }, 0);
    },
    proove: function() {
      axios
        .post("https://old.rem.biz/proove.php", {
          key: this.key,
        })
        .then((response) => {
          if (response.data == "ok") {
            this.deleteItems();
            this.key = "";
            this.dialog_delete = false;
          } else {
            this.error = [];
            this.error.push("Неверно");
          }
        });
    },
    getCostTypes: function() {
      axios
        .get(`https://old.rem.biz/costsTypes.php?td=personal`)
        .then((response) => {
          this.select_items = [];
          this.personal = response.data;
          response.data.forEach((el) => {
            this.select_items.push(el.name);
          });
        });
    },
    calcStat: function() {
      this.totals = {};
      this.total_items = 0;
      this.costs.forEach((el) => {
        if (!this.totals[el.name]) {
          this.totals[el.name] = 0;
        }

        el.salary = parseInt(el.salary);
        this.totals[el.name] = this.totals[el.name] + parseInt(el.salary);
        this.total_items = this.total_items + parseInt(el.salary);
      });

      let labels = [];
      this.series = [];
      for (let prop in this.totals) {
        labels.push(`${prop} ${this.totals[prop]}₽`);
        this.series.push(this.totals[prop]);
      }
      this.options = {
        labels: labels,

        responsive: [
          {
            breakpoint: 90000,
            options: {
              legend: {
                position: "right",
              },
            },
          },
          {
            breakpoint: 900,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
    addItem: function() {
      let name_id = this.personal.find((el) => el.name == this.new_item.name);
      this.new_item.name_id = name_id.id;
      axios
        .post("https://old.rem.biz/createSalary.php", this.new_item)
        .then((response) => {
          if (response.text != "error") {
            response.data.date = new Date(
              response.data.salary_date
            ).toLocaleDateString();
            this.costs.unshift(response.data);
            this.new_item = {
              name: "",
              salary: 0,
              comment: "",
              salary_date: this.now,
            };
          }
          this.calcStat();
          this.dialog = false;
        });
    },
    editItem: function() {
      let name_id = this.personal.find((el) => el.name == this.edit_item.name);
      this.edit_item.name_id = name_id.id;
      axios
        .post("https://old.rem.biz/editSalary.php", this.edit_item)
        .then((response) => {
          if (response.text != "error") {
            this.dialog_edit = false;
            this.selected = [];
          }
          this.calcStat();
        });
    },
    deleteItems: function() {
      let ids = this.selected.map((el) => {
        return el.id;
      });
      axios
        .get("https://old.rem.biz/deleteCostsType.php", {
          params: {
            to_del: ids,
            table: "salary_list",
          },
        })
        .then((response) => {
          if (response.data == "ok") {
            ids.forEach((id) => {
              let ind = this.costs.findIndex((el) => {
                return el.id == id;
              });

              this.costs.splice(ind, 1);
            });
            this.selected = [];
            this.calcStat();
          }
        });
    },
    getTimeCost: function() {
      axios
        .get(
          `https://old.rem.biz/getTimeSalary.php?from=${this.from}&to=${this.to}`
        )
        .then((response) => {
          this.costs = response.data;
          this.costs.forEach((el) => {
            el.date = new Date(el.date).toLocaleDateString();
          });
          this.unfilter = this.costs.slice();
          this.calcStat();
        });
    },
  },
  computed: {
    edit_item: function() {
      return this.selected[0];
    },
    to1: function() {
      return new Date(this.to).toLocaleDateString();
    },
    from1: function() {
      return new Date(this.from).toLocaleDateString();
    },
    total: function() {
      return this.total_aparts + this.total_job;
    },
  },
});
</script>
<style>
.prefooter {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
</style>
